export const LinkboxCarouselGrid = (length) => {
  let gridLayout
  switch (length) {
    case 2:
      gridLayout = [{ md: 6 }, { md: 6 }]
      break
    case 3:
      gridLayout = [
        { xs: 6, sm: 6, md: 6, lg: 4, xl: 4 },
        { xs: 6, sm: 6, md: 6, lg: 4, xl: 4 },
        { xs: 6, sm: 6, md: 6, lg: 4, xl: 4 },
      ]
      break
    case 4:
      gridLayout = [
        { xs: 6, sm: 6, md: 6, lg: 3, xl: 3 },
        { xs: 6, sm: 6, md: 6, lg: 3, xl: 3 },
        { xs: 6, sm: 6, md: 6, lg: 3, xl: 3 },
        { xs: 6, sm: 6, md: 6, lg: 3, xl: 3 },
      ]
      break
    case 5:
      gridLayout = [
        { xs: 6, sm: 6, md: 6, lg: 6, xl: 6 },
        { xs: 6, sm: 6, md: 6, lg: 6, xl: 6 },
        { xs: 6, sm: 6, md: 6, lg: 4, xl: 4 },
        { xs: 6, sm: 6, md: 6, lg: 4, xl: 4 },
        { xs: 6, sm: 6, md: 6, lg: 4, xl: 4 },
      ]
      break
    case 6:
      gridLayout = [
        { xs: 6, sm: 6, md: 6, lg: 4, xl: 4 },
        { xs: 6, sm: 6, md: 6, lg: 4, xl: 4 },
        { xs: 6, sm: 6, md: 6, lg: 4, xl: 4 },
        { xs: 6, sm: 6, md: 6, lg: 4, xl: 4 },
        { xs: 6, sm: 6, md: 6, lg: 4, xl: 4 },
        { xs: 6, sm: 6, md: 6, lg: 4, xl: 4 },
      ]
      break
    default:
      gridLayout = [{ md: 12 }]
      break
  }

  return gridLayout
}
