import { css } from '@emotion/react'
import { Grid, GridItem } from '@dfds-ui/grid'

const PageLayout = (props) => {
  const { children, spaced, sidebar } = props

  return (
    <Grid top={spaced && 'l'} gap="l" surface="dark">
      <GridItem
        large={sidebar ? 8 : 12}
        css={css`
          width: 100%;
          max-width: 100%;
        `}
      >
        {children}
      </GridItem>
      {sidebar && <GridItem large={4}>{sidebar}</GridItem>}
    </Grid>
  )
}

export default PageLayout
