export function isEmptyTypeObject(data) {
  if (!data) {
    return true
  }

  if (typeof data === 'object') {
    const numOfKeys = Object.keys(data).length

    return (
      (typeof data.__typename === 'string' && numOfKeys === 1) ||
      numOfKeys === 0
    )
  }

  return false
}

export const isContentfulType = (__typename, testTypename) =>
  __typename === `contentful_${testTypename}` ||
  __typename === testTypename.split('contentful_')[1] ||
  __typename === testTypename
