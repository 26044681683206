import { transformLocaleToContententfulsLocale } from '@dfds-frontend/contentful-api-calls'
import { createClient } from 'contentful-management'

function createContentfulClientCache<T>(
  clientCreation: (
    contentfulSpace: string,
    env: string,
    accessToken: string,
    preview?: boolean,
  ) => T,
): typeof clientCreation {
  const clientLookup = {} as Record<string, T>
  return (contentfulSpace, env, accessToken, preview = false) => {
    const key = `${contentfulSpace}${env}${preview}`

    if (!clientLookup[key]) {
      clientLookup[key] = clientCreation(
        contentfulSpace,
        env,
        accessToken,
        preview,
      )
    }

    return clientLookup[key]
  }
}

export const createContentfulManagementClient = createContentfulClientCache(
  async (contentfulSpace, env, accessToken) => {
    const client = createClient({
      accessToken: accessToken,
    })
    const space = await client.getSpace(contentfulSpace)
    const environment = await space.getEnvironment(env)
    return environment
  },
)

export const hasOwnLocalizedContent = async (type, id, filter, locale) => {
  const environment = await createContentfulManagementClient(
    process.env.GATSBY_CONTENTFUL_SPACE as string,
    process.env.GATSBY_CONTENTFUL_ENV as string,
    process.env.GATSBY_CONTENTFUL_MANAGEMENT_TOKEN as string,
  )

  const { items } = await environment.getEntries({
    content_type: type,
    'sys.id': id,
    select: filter,
  })

  if (!items || (items && !items[0])) return false

  const filteredContent = eval(`items[0]?.${filter.replace(/\./g, '?.')}`)
  const contentfulLocale = transformLocaleToContententfulsLocale(locale)
  return !!(filteredContent && filteredContent[contentfulLocale])
}
