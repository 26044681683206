import * as locales from 'date-fns/locale'
import {
  startOfWeek,
  endOfWeek,
  format as formatFns,
  getWeek as getWeekFns,
} from 'date-fns'

const getTranslatedMonth = (monthNumber, t) => {
  const months = [
    t('GENERAL-JANUARY'),
    t('GENERAL-FEBRUARY'),
    t('GENERAL-MARCH'),
    t('GENERAL-APRIL'),
    t('GENERAL-MAY'),
    t('GENERAL-JUNE'),
    t('GENERAL-JULY'),
    t('GENERAL-AUGUST'),
    t('GENERAL-SEPTEMBER'),
    t('GENERAL-OCTOBER'),
    t('GENERAL-NOVEMBER'),
    t('GENERAL-DECEMBER'),
  ]
  return months[monthNumber]
}

const getTranslatedMonthShort = (monthNumber, t) => {
  const months = [
    t('GENERAL-JANUARY-SHORT'),
    t('GENERAL-FEBRUARY-SHORT'),
    t('GENERAL-MARCH-SHORT'),
    t('GENERAL-APRIL-SHORT'),
    t('GENERAL-MAY-SHORT'),
    t('GENERAL-JUNE-SHORT'),
    t('GENERAL-JULY-SHORT'),
    t('GENERAL-AUGUST-SHORT'),
    t('GENERAL-SEPTEMBER-SHORT'),
    t('GENERAL-OCTOBER-SHORT'),
    t('GENERAL-NOVEMBER-SHORT'),
    t('GENERAL-DECEMBER-SHORT'),
  ]
  return months[monthNumber]
}

const getTranslatedWeekDay = (dayNumber, t) => {
  const weekDays = [
    t('GENERAL-MONDAY'),
    t('GENERAL-TUESDAY'),
    t('GENERAL-WEDNESDAY'),
    t('GENERAL-THURSDAY'),
    t('GENERAL-FRIDAY'),
    t('GENERAL-SATURDAY'),
    t('GENERAL-SUNDAY'),
  ]

  return weekDays[dayNumber]
}

const getTranslatedWeekDayShortname = (dayNumber, t) => {
  const weekDays = [
    t('GENERAL-SHORTNAME-MONDAY'),
    t('GENERAL-SHORTNAME-TUESDAY'),
    t('GENERAL-SHORTNAME-WEDNESDAY'),
    t('GENERAL-SHORTNAME-THURSDAY'),
    t('GENERAL-SHORTNAME-FRIDAY'),
    t('GENERAL-SHORTNAME-SATURDAY'),
    t('GENERAL-SHORTNAME-SUNDAY'),
  ]

  return weekDays[dayNumber]
}

const formatDate = (date) => {
  const dateObject = new Date(date)
  const dateArray = getDateString(dateObject)
  return `${dateArray[0]} ${dateArray[1]} ${dateArray[2]}`
}

const formatLocalizedDate = (date, format, locale) =>
  formatFns(date, format, { locale: getDateFnsLocale(locale) })

const getDateString = (date) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }
  return date.toLocaleDateString('en-GB', options).split(' ')
}

const getFirstDayOfWeek = (locale) =>
  getDateFnsLocale(locale).options.weekStartsWith

const getFirstDateOfWeek = (date, locale) =>
  startOfWeek(date, { locale: getDateFnsLocale(locale) })

const getLastDateOfWeek = (date, locale) =>
  endOfWeek(date, { locale: getDateFnsLocale(locale) })

const getWeek = (date, locale) =>
  getWeekFns(date, { locale: getDateFnsLocale(locale) })

const getFirstWeekStartsOn = (locale) =>
  getDateFnsLocale(locale).options.firstWeekContainsDate

const getDateFnsLocale = (locale) =>
  locales[locale.replace(/-/g, '')] ?? // da-DK -> daDK
  locales[locale.slice(0, 2)] ?? // da-DK -> da
  locales.enGB

export {
  getTranslatedMonth,
  getTranslatedMonthShort,
  formatDate,
  formatLocalizedDate,
  getTranslatedWeekDay,
  getTranslatedWeekDayShortname,
  getFirstDayOfWeek,
  getFirstDateOfWeek,
  getLastDateOfWeek,
  getWeek,
  getFirstWeekStartsOn,
}
