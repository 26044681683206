const gridMap = {
  'one-third': { md: 4 },
  'two-thirds': { md: 8 },
  'one-fourth': { md: 3 },
  'one-half': { md: 6 },
  'three-fourths': { md: 9 },
  'full-width': { md: 12 },
}

const flexMap = (col) => {
  switch (col) {
    case 1:
      return '1 1 8.333333%'
    case 2:
      return '1 1 16.666667%'
    case 3:
      return '1 1 25%'
    case 4:
      return '1 1 33.333333%'
    case 5:
      return '1 1 41.666667%'
    case 6:
      return '1 1 50%'
    case 7:
      return '1 1 58.333333%'
    case 8:
      return '1 1 calc(66.666667% - 30px)'
    case 9:
      return '1 1 75%'
    case 10:
      return '1 1 83.333333%'
    case 11:
      return '1 1 91.666667%'
    default:
      return '1 1 100%'
  }
}

export { gridMap, flexMap }
